@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
[aria-label] {
  position: relative;
}
.lbl-imp {
  text-shadow: -1.5px 0 white, 0 1px white, 1.5px 0 white, 0 -1.5px white;
}

.App {
  text-align: center;
  /* width: 100%;
  height: 100%;
  height: 100%; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

#root,
html,
body {
  /* height: 100%; */
  /* background-color: #f6f7f8; */
  background-color: #f1f6fb;
}
.google-map {
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

::-webkit-scrollbar {
  /* background-color: red; */
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgb(212, 212, 212, 0.4);
  border-radius: 10px;
  width: 5px;
  border: solid 1px rgb(214, 214, 214);
  margin: 22px;
}
